@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";
@import "partials/_breakpoints";

@import "partials/_cart.scss";

$max-width: 1440px;
$min-width: 320px;

// Fonts-
@import url("https://use.typekit.net/idw3ofg.css");

// Global Styles
*,
*:before,
*:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
html {
    font-family: $font;
    position: relative;
    text-align: center;
    background: $black;
    overflow-x: hidden;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    color: $white;
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    flex: 1;
}

a {
    color: $white;
}

// Error pages
.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0.5em;
}

.container {
    padding: 20px;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;
    .row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0 auto;
    }
}

nav {
    &.breadcrumbs {
        text-align: left;
        ul {
            display: inline-block;
            li {
                display: inline-block;
                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                }
            }
        }
    }
}
body > header {
    nav {
        ul {
            li {
                list-style: none;
                display: inline-block;
            }
        }
        // &.user {
        //     .cart {
        //         box-shadow: 5px 5px 5px rgba($off-black, 0.2);
        //         .cart-items {
        //             padding-top: 10px!important;
        //             padding-bottom: 0!important;
        //             .cart-item {
        //                 padding: 0 0;
        //                 .item-details {
        //                     flex: 1 1 auto;
        //                 }
        //                 .item-qty {
        //                     flex: 0 0 auto;
        //                 }
        //                 .item-price {
        //                     flex: 1 1 auto;
        //                     text-align: right;
        //                 }
        //                 .item-remove {
        //                     flex: 0 0 auto;
        //                     margin-right: -10px;
        //                     margin-left: 10px;
        //                 }
        //             }
        //         }
        //         .totals {
        //             font-weight: 600;
        //             padding:  5px 10px 5px 5px;
        //             border-top: 1px dotted $off-black;
        //         }
        //     }
        // }
    }
}

main {
    &.account,
    &.checkout {
        ul.sections {
            justify-content: center;
            li {
                display: block;
                padding: 0 10px;
                a {
                    display: block;
                    border: 1px solid $black;
                    border-radius: 5px;
                    padding: 10px 20px;
                }
                &.current {
                    a {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }
        form {
            max-width: 600px;
            flex: 1 0 auto;
        }
        &.address,
        &.cart {
            form {
                max-width: 980px;
                flex: 1 0 auto;
            }
        }
    }
    &.checkout {
        &.cart {
            .cart-items {
                & > li.cart-item {
                    padding: 5px 10px;
                    input {
                        margin: 0;
                    }
                    .item-image {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 auto;
                    }
                    .item-details {
                        text-align: left;
                        justify-self: flex-start !important;
                        flex: 1 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                    }
                    .item-qty {
                        flex: 0 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                        input {
                            display: inline-block;
                            width: 8rem;
                        }
                    }
                    .item-price {
                        flex: 0 0 auto;
                        text-align: right;
                        max-width: 8rem;
                        padding: 0 10px;
                    }
                    .item-remove {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .totals {
                font-weight: 600;
                padding: 0 20px;
                span {
                    display: inline-block;
                    min-width: 8rem;
                }
            }
        }
        &.shipping,
        &.payment {
            & > .container > .row {
                justify-content: center;
                align-items: flex-start;
            }
            form {
                margin: 0;
                fieldset {
                    &.shipping-methods {
                        padding: 20px;
                        label {
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            input {
                                margin-right: 1em;
                            }
                            span {
                                font-weight: 600;
                                display: inline-block;
                                margin-left: 1em;
                            }
                        }
                    }
                    &.buttons {
                        display: flex;
                        justify-content: center;
                        button,
                        .button {
                            margin: 2em 10px 0;
                        }
                    }
                }
                .payment-method {
                    margin-top: 20px;
                    padding-top: 20px;
                }
                .message {
                    padding: 20px 10px;
                    text-align: left;
                    ul {
                        margin: 1em 2em;
                    }
                }
            }
            .totals {
                border-radius: 5px;
                padding: 40px 40px;
                max-width: 330px;
                margin: 0 10px;
                flex: 1 0 auto;
                text-align: left;
                ul {
                    margin: 1em 0;
                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        padding: 0.5em 5px;
                        &.total {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

main {
    &.products {
        ul.row {
            justify-content: flex-start;
            li.product {
                list-style: none;
                border: 1px solid $black;
                overflow: hidden;
                width: calc(25% - 20px);
                margin: 10px;
                a {
                    &.image {
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                        background-size: contain;
                    }
                }
                div {
                    text-align: left;
                    padding: 20px 40px;
                }
            }
        }
        &.single {
        }
    }
}

// Flatpickr
.flatpickr-wrapper {
    @apply w-full;
}

.flatpickr-calendar.open {
    z-index: 1000 !important;
    filter: none !important;
}

.flatpickr-months {
    @apply mb-2 text-gray-800;
}

.flatpickr-next-month,
.flatpickr-prev-month {
    @apply text-gray-800;

    svg {
        fill: currentColor !important;
    }
}

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
    @apply bg-transparent;
}

.flatpickr-day {
    @apply border-0;

    &.today {
        @apply font-semibold;

        &:hover {
            color: inherit;
            @apply bg-gray-400;
        }
    }

    &:hover {
        @apply bg-gray-400;
    }

    &.selected {
        @apply bg-blue-600 text-white;

        &:hover,
        &.today:hover {
            @apply bg-blue-400 text-white;
        }
    }
}

.flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm,
    .flatpickr-time {
        background: none !important;
    }
}

.flatpickr-time input.flatpickr-hour,
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    @apply font-semibold;

    &::selection {
        background: none;
    }
}

// -- Flash Bastard Exp

// Pagination

.pagination {
    display: flex;
    width: 100%;
    margin: 3.125rem 0 0;
    padding: 0;
    justify-content: center;

    li {
        display: block;
        margin: 0;
        padding: 0;
        list-style: none;

        &:hover:not(.active) {
            a {
                background-color: $light-grey;
            }
        }

        &.active {
            a, span {
                background: $primary;
                font-weight: 700;
                color: $white;
            }
        }

        a, span {
            display: flex;
            background: transparent;
            width: 1.75rem;
            height: 1.75rem;
            margin: 0 0.25rem;
            padding: 0;
            border-radius: 0.25rem;
            border: none;
            transition: background-color .3s;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            text-align: center;
            font-weight: 400;
            font-size: 0.875rem;
            color: $white;
        }
    }
}

// General
.container {
    padding: 0 40px;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;

    @include md {
        padding: 0 20px;
    }
}

.row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    @include md {
        flex-direction: column;
        align-items: flex-start;
    }

    &.reverse {
        @include xs {
            flex-direction: column-reverse;
        }

        .column {
            &:first-child {
                margin-top: 1em;
            }
        }
    }

    .column {
        width: calc(50% - 2em);

        @include md {
            width: 100%;
        }

        &.right {
            @include md {
                margin-top: 2em;
            }
        }
    }
}

// Page Text
p {
    color: $white;
    font-size: 2.25rem;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 1em;

    @include lg {
        font-size: 2rem;
        line-height: 40px;
    }

    @include md {
        font-size: 1.75rem;
        line-height: 36px;
    }

    @include xs {
        font-size: 1.5rem;
        line-height: 32px;
    }
}

h2 {
    color: $white;
    font-size: 2.45rem;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 1em;

    @include lg {
        font-size: 2rem;
        line-height: 40px;
    }

    @include md {
        font-size: 1.75rem;
        line-height: 36px;
    }

    @include xs {
        font-size: 1.5rem;
        line-height: 32px;
    }
}

// Footer
footer {
    margin: 2em auto;

    .container {
        .row {
            flex-direction: column;
            margin: 1em auto;
            justify-content: center;

            .social-medias {
                margin: 0 auto 1em;
            }

            .credit {
                display: flex;
                font-size: 0.75rem;
                color: $white;

                @include xs {
                    flex-direction: column;
                    justify-content: center;
                    margin: 0 auto;
                }

                a {
                    color: $white;
                }

                span {
                    &:last-child {
                        margin-left: 2em;

                        @include xs {
                            margin: 0.5em 0 0 0;
                        }
                    }
                }
            }
        }
    }
}

// Header

header {
    position: relative;
    width: 100%;
    z-index: 5;

    .row {
        flex-direction: row !important;
        align-items: center;
    }

    .logo {
        position: relative;
        display: inline-flex;
        background-image: url("/img/fbe-logo.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 17em;
        height: 8em;
        text-indent: -999em;
        z-index: 1;

        @include lg {
            width: 12em;
            height: 6em;
        }

        @include xs {
            width: 10em;
            height: 6em;
        }
    }

    .desktop-menu {
        display: flex;
        flex-direction: row;
        align-items: center;

        nav {
            display: flex;
            align-items: center;

            ul {
                li {
                    position: relative;
                    margin: 0 1em;

                    &:hover {
                        ul {
                            transform: translate(0, 0);
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    a,
                    span {
                        padding: 0.625rem 0;
                        color: $white;
                        font-weight: 400;
                        font-size: 1rem;
                        text-decoration: none;
                        text-transform: uppercase;

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: -5px;
                            background: $primary;
                            width: 0;
                            height: 3px;
                            transition: all 0.3s ease;
                        }

                        &:hover,
                        &.active {
                            color: $primary;

                            &:after {
                                width: 100%;
                            }
                        }
                    }

                    ul {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        top: calc(100% + 0.5rem);
                        left: 0;
                        background: $white;
                        width: 11rem;
                        padding: 0.5rem 0;
                        box-shadow: 0 3px 5px rgba(38, 42, 47, 0.15);
                        transform: translateY(1rem);
                        transition: all 0.3s ease;
                        visibility: hidden;
                        opacity: 0;
                        z-index: 1;

                        li {
                            margin: 0.25rem 0;

                            a {
                                display: block;
                                font-size: 1rem;
                                padding: 0.25rem 1.5rem;
                                text-align: left;
                                text-transform: none;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    background: $primary;
                                    width: 3px;
                                    height: 0;
                                    transition: all 0.3s ease;
                                }

                                &:after {
                                    content: none;
                                }

                                &:hover,
                                &.active {
                                    &:before {
                                        height: 100%;
                                    }
                                }
                            }

                            ul {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// -- Mobile menu

header .desktop-menu {
    display: flex;

    @include lg {
        display: none;
    }
}

.mobile-menu {
    display: none;

    @include lg {
        position: relative;
        display: block;
        z-index: 101;
    }

    .row {
        align-items: center;

        @include md {
            flex-direction: row;
        }
    }

    .logo-link {
        z-index: 1000;
    }

    .logo {
        width: 12em;
    }

    .mobile-menu-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5em;

        .column.right {
            display: flex;
            flex-direction: row;
        }
    }
}

.burger-menu {
    display: none;

    @include lg {
        width: 30px;
        height: 30px;
        display: block;
        padding: 0;
        border-bottom: 0 none;
        position: relative;
        right: 0;
        cursor: pointer;
        z-index: 101;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            .top {
                transform: translateY(11px) translateX(0) rotate(45deg);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                transform: translateY(-9px) translateX(0) rotate(-45deg);
            }
        }

        span {
            background: $white;
            border: none;
            height: 2px;
            width: 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.35s ease;
            cursor: pointer;
            z-index: 101;

            &:nth-of-type(2) {
                top: 14px;
            }

            &:nth-of-type(3) {
                top: 24px;
            }
        }
    }
}

.overlay {
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    z-index: 100;

    &.open {
        opacity: 1;
        visibility: visible;
        height: 100%;

        li {
            animation: fadeInRight 0.5s ease forwards;
            animation-delay: 0.35s;

            &:nth-of-type(2) {
                animation-delay: 0.4s;
            }

            &:nth-of-type(3) {
                animation-delay: 0.45s;
            }

            &:nth-of-type(4) {
                animation-delay: 0.5s;
            }

            &:nth-of-type(5) {
                animation-delay: 0.55s;
            }

            &:nth-of-type(6) {
                animation-delay: 0.6s;
            }

            &:nth-of-type(7) {
                animation-delay: 0.65s;
            }

            &:nth-of-type(8) {
                animation-delay: 0.7s;
            }

            &:nth-of-type(9) {
                animation-delay: 0.75s;
            }

            &:nth-of-type(10) {
                animation-delay: 0.8s;
            }

            &:nth-of-type(11) {
                animation-delay: 0.85s;
            }

            &:nth-of-type(12) {
                animation-delay: 0.9s;
            }
        }
    }

    @include md {
        opacity: 0;
        visibility: hidden;
    }
}

.overlay-menu {
    nav {
        display: block;
        position: fixed;
        top: 5.125em;
        left: 50%;
        right: 0;
        bottom: 0;
        transform: translate(-50%, 0);
        background: $black;
        width: 100%;
        height: 100%;
        padding: 1rem 0 7em 0;
        transition: all 0.3s ease;
        overflow-x: scroll;
        z-index: 2;

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            flex-direction: column;
            width: 100%;
            margin: 0;
            padding-left: 0;

            li {
                text-align: left;
                margin-bottom: 0;
                padding: 0.15em 1.25em;
                opacity: 0;

                a {
                    display: block;
                    width: 100%;
                    color: $white;
                    font-size: 2rem;
                    text-decoration: none;
                    padding: 0.5em;

                    &:hover {
                        color: $primary;
                    }

                    @include xs {
                        font-size: 1.5rem;
                    }
                }

                ul {
                    margin: 0 0 0.5em;

                    li {
                        ul {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}

// Social Medias

.social-medias {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 0.5em;
    z-index: 5;

    a {
        padding: 0 0.25rem;

        &:hover {
            svg {
                background-color: $primary;
            }
        }
    }

    svg {
        width: 2em;
        height: 2em;
        background-color: $white;
        padding: 0.5em;
        border-radius: 100%;

        path,
        rect {
            fill: $black;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
        }
    }
}

// Linked

.linked {
    display: block;
    font-size: 2rem;
    font-weight: 300;
    color: $white;
    text-decoration: underline;
    margin: 1em 0;
    text-align: left;

    &:hover {
        color: $primary;
    }

    @include md {
        font-size: 1.75rem;
    }

    @include xs {
        font-size: 1.5rem;
    }
}

// Col grid

.col-grid-4 {
    display: grid;
    gap: 2em;
    grid-template-columns: repeat(4, 1fr);
    margin: 2em 0;

    @include lg {
        gap: 1em;
    }

    @include md {
        grid-template-columns: repeat(3, 1fr);
    }

    @include xs {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2em;
    }

    @include xxs {
        grid-template-columns: repeat(1, 1fr);
    }

    .col-item {
        display: flex;
        flex-direction: column;

        .col-img {
            display: block;
            width: 25em;
            height: 25em;

            @include lg {
                width: 14em;
                height: 14em;
            }

            @include xs {
                width: 10em;
                height: 10em;
            }
        }

        .col-title {
            display: block;
            color: $white;
            font-size: 1.25rem;
            text-align: left;
            margin: 1em 0 0.5em;

            @include xs {
                font-size: 1.125rem;
            }
        }

        .col-subtitle {
            display: block;
            color: $white;
            text-align: left;

            @include xs {
                font-size: 0.75rem;
            }
        }
    }
}

// Homepage

#home-main-banner {
    position: relative;
    width: 100%;
    height: 100%;
}

#home-slider-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    transform: translatez(0);

    .slick-slide {
        display: block;
        width: 100vw;
        height: 100vh;
        background: #11110b;

        @include lg {
            height: 50vh;
        }

        @include xs {
            height: 35vh;
        }

        &:before {
            opacity: 1;
            background: linear-gradient(
                to top,
                rgb(0, 0, 0) 15%,
                rgba(0, 0, 0, 0.1) 50%,
                transparent
            );
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80%;
            background: -webkit-linear-gradient(
                top,
                rgba(0, 0, 0, 0) 0,
                rgba(0, 0, 0, 0.8) 80%,
                rgba(0, 0, 0, 0.8) 100%
            );
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 0,
                rgba(0, 0, 0, 0.8) 80%,
                rgba(0, 0, 0, 0.8) 100%
            );
        }

        figure {
            position: relative;
            height: 100%;
            margin: 0;
            padding: 0;
        }

        .slide-image {
            opacity: 1 !important;
            background-size: cover;
            background-position: center;
            //background-attachment: fixed;
            width: 100%;
            height: 100%;
            transition: all 0.8s ease;

            .image-entity {
                width: 100%;
                visibility: hidden;
                opacity: 0;
            }
        }
    }

    .slick-dots {
        display: none;
    }

    .slick-dots li.slick-active button:before {
        color: $primary;
    }

    .slick-prev,
    .slick-next {
        top: 50%;
        z-index: 99;
    }

    .container {
        height: auto;
        width: 100%;
        position: relative;
    }

    .text-container {
        position: absolute;
        width: 100%;
        max-width: calc(100% - 4em);
        z-index: 2;
        left: 2em;
        bottom: 15em;
        text-align: left;
        border-bottom: 1px solid lightgrey;
        padding-bottom: 1em;

        @include lg {
            bottom: 4em;
        }

        .text {
            max-width: 1200px;
        }

        span.small-text {
            display: block;
            color: $white;
            font-size: 1.25rem;
            text-transform: uppercase;
            letter-spacing: 3px;
        }

        h1.headline {
            color: $white;
            font-size: 3.5rem;
            text-transform: uppercase;
            margin: 0.25em 0 0.15em;

            @include md {
                font-size: 3rem;
            }

            @include xs {
                font-size: 2rem;
            }
        }

        .subhead {
            color: $white;
            font-weight: 400;
            font-size: 1rem;
            text-transform: uppercase;
        }

    }

    .scroll-more {
        margin: 0;
        z-index: 5;
        display: block;
        position: relative;
        top: -11em;
        padding: 0;
        background: transparent;
        color: $white;
        border: none;
        right: 0;
        width: 100%;
        height: fit-content;
        text-align: right;
        font-size: 1.125rem;
        text-decoration: none;
        cursor: pointer;
        overflow: hidden;

        @include lg {
            top: -1.5em;
        }

        svg {
            height: auto;
            width: 0.85rem;
            animation: 3s ease 0s infinite normal both running scroll-more;
            margin-left: 1.5em;
        }
    }
}

#player-embed {
    position: relative;

    .spotify-embed {
        position: absolute;
        display: block;
        z-index: 5;
    }
}

#home-about-text {
    //display: flex;
    //justify-content: flex-end;
    //padding-top: 8em;
    //padding-bottom: 12em;
    //width: 100%;

    @include lg {
        padding-top: 6em;
        padding-bottom: 8em;
    }

    @include md {
        padding-top: 4em;
        padding-bottom: 6em;
    }

    @include xs {
        padding-top: 4em;
        padding-bottom: 4em;
    }

    .text-container {
        // width: 45em;

        @include lg {
            // width: 35em;
        }

        span.section-title {
            display: block;
            color: $white;
            font-size: 2.5rem;
            text-align: left;

            @include md {
                font-size: 2rem;
            }

            @include xs {
                font-size: 1.5rem;
            }
        }

        p {
            color: $white;
            font-size: 2.25rem;
            line-height: 42px;
            letter-spacing: 0px;
            text-align: left;
            margin-bottom: 1em;

            @include lg {
                font-size: 2rem;
                line-height: 40px;
            }

            @include md {
                font-size: 1.75rem;
                line-height: 36px;
            }

            @include xs {
                font-size: 1.5rem;
                line-height: 32px;
            }
        }

        h2 {
            color: $white;
            font-size: 2.45rem;
            line-height: 42px;
            letter-spacing: 0px;
            text-align: left;
            margin-bottom: 1em;

            @include lg {
                font-size: 2rem;
                line-height: 40px;
            }

            @include md {
                font-size: 1.75rem;
                line-height: 36px;
            }

            @include xs {
                font-size: 1.5rem;
                line-height: 32px;
            }
        }
    }
}

#upcoming-events {
    padding-bottom: 8em;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    //border-top: 1px solid $white;

    @include lg {
        padding-bottom: 6em;
    }

    @include md {
        padding-bottom: 4em;
    }

    @include xs {
        padding-bottom: 2em;
    }

    .content-container {
        width: 45em;

        span.section-title {
            display: block;
            color: $white;
            font-size: 2.5rem;
            text-align: left;

            @include md {
                font-size: 2rem;
            }

            @include xs {
                font-size: 1.5rem;
            }
        }

        .events-list {
            width: 100%;
            margin: 2em auto;
            display: grid;
            gap: 1em;
            grid-template-columns: repeat(2, 1fr);

            @include md {
                grid-template-columns: repeat(1, 1fr);
            }

            @include xs {
                margin: 1em auto;
            }

            .event-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                list-style-type: none;
                background: rgba(0, 0, 0, 0.75);
                margin: 1em 0;
                filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
                flex-direction: column;
                border: 1px solid #989898;
                margin-right: 2em;
                min-width: 40em;

                &:hover {
                    border: 1px solid $primary;
                }

                @include lg {
                    min-width: 28em;
                }

                @include xs {
                    margin-right: 0;
                    min-width: auto;
                }

                .event-img {
                    display: block;
                    width: 100%;
                }

                .details-row {
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                .date {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: center;
                    color: $black;
                    background: $primary;
                    padding: 1em;
                    margin-right: 2em;
                    border-radius: 0 0 0;
                    width: 9em;

                    @include md {
                        width: 7em;
                    }

                    .date-number {
                        color: $black;
                        font-size: 2.75rem;
                        font-family: $heading;
                        font-weight: 600;
                        margin-bottom: 0.25em;

                        @include xs {
                            font-size: 2.5rem;
                        }
                    }

                    .date-month {
                        color: $black;
                        font-size: 1rem;
                        text-transform: uppercase;

                        @include xs {
                            font-size: 0.75rem;
                        }
                    }
                }

                .event-details {
                    padding: 0.75em 0;
                    text-align: left;
                    color: $white;

                    span.event-small-text {
                        display: block;
                        text-transform: uppercase;
                        font-size: 1rem;
                        margin-bottom: 0.5em;
                    }

                    .event-name {
                        display: block;
                        font-size: 1.75rem;
                        margin-bottom: 0.5em;

                        @include xs {
                            font-size: 1.5rem;
                        }
                    }

                    p {
                        margin: 0.25em 0;
                    }
                }
            }
        }
    }
}

#latest-release {
    padding-bottom: 8em;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    @include lg {
        padding-bottom: 6em;
    }

    @include md {
        padding-bottom: 4em;
    }

    @include xs {
        padding-bottom: 2em;
    }

    .content-container {
        width: 45em;

        span.section-title {
            display: block;
            color: $white;
            font-size: 2.5rem;
            text-align: left;

            @include md {
                font-size: 2rem;
            }

            @include xs {
                font-size: 1.5rem;
            }
        }
    }
}

// Animations

@-webkit-keyframes scroll {
    100% {
        -webkit-background-position: -3000px 0px;
    }
}

@keyframes scroll {
    100% {
        background-position: -3000px 0px;
    }
}

@keyframes fade-slide-up {
    0% {
        opacity: 0;
        transform: translateY(4rem);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes pulse {
    0% {
        opacity: 1;
        transform: none;
    }

    50% {
        opacity: 0.8;
        transform: scale(0.8);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        left: 20%;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

@keyframes scroll-more {
    25% {
        transform: none;
    }

    50% {
        transform: translateY(100%) translateX(-100%);
    }

    74.999% {
        transform: translateY(100%) translateX(-100%);
    }

    75% {
        transform: translateY(-100%) translateX(100%);
    }
}
