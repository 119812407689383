@import "../partials/_breakpoints";

.content-block {
  &.cols-2 {
    display: flex;
    flex-wrap: wrap;
    > * {
      flex-basis: 50%;
      max-width: 50%;
      padding: 0 2em 0 0;

      @include md {
        flex-basis: 100%;
        max-width: 100%;
      }
      img {
        max-height: 400px;
      }
    }
  }
  &.cols-3 {
    display: flex;
    flex-wrap: wrap;
    > * {
      flex-basis: 33.3333333%;
      max-width: 33.3333333%;
      padding: 0 2em 0 0;
      img {
        max-height: 400px;
      }
    }
  }
}
